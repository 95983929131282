export const riskScoreList = {
  0: "#d3d3d3",
  1: "#72ab46",
  2: "#9ecf78",
  3: "#c0d853",
  4: "#d7ea85",
  5: "#ffe673",
  6: "#f9c74f",
  7: "#f8961e",
  8: "#f3722c",
  9: "#f94144",
  10: "#ff2a00",
}

export const riskKindList = {
  0: "#5A78EA",
}
export const riskScoreTextList = {
  0: "#ffffff",
  1: "#ffffff",
  2: "#ffffff",
  3: "#091540",
  4: "#091540",
  5: "#091540",
  6: "#091540",
  7: "#ffffff",
  8: "#ffffff",
  9: "#ffffff",
  10: "#ffffff",
}

export const riskKindTextList = {
  0: "#ffffff",
}
